import React from 'react'
import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'

const About = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout color="#fff">
      <SEO title="About | Mobius Teknologi Indonesia" desc="Hi. We are Mobius Teknologi Indonesia!." />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
        <h1>Hi. We're Mobius!</h1>
        <p>We empower your business with technology</p>
        <p>
          Mobius Teknologi is a <b>strategic software consultant</b> based in Jakarta, Indonesia.
        <p>
          We are committed to help your business to do more with technology,
          and to do that we listen and work alongside throughout the entire process
          from idea to reality to provide the most informative and productive experience possible.
        </p>
          Here are the steps of the journey we take together.
          <ul>
            <li>Idea
              <p>Our team of consultants listen and observe your existing solutions, discover key problems and opportunities for improvement and develop a road map to your business needs.</p>
            </li>
            <li>Development
              <p>We develop and test the technology that we created to be integrated into your daily business operations.</p>
            </li>
            <li>Support
              <p>We help you in setup and implementing new technology into your daily business operations.</p>
            </li>
          </ul>
        </p>
      </AnimatedBox>
    </Layout>
  )
}

export default About
